<template>
  <div>
    <v-card outlined class="mb-4">
      <v-card-title>{{ $t("URL settings") }}</v-card-title>
      <v-card-text>
        <h3 class="mb-3">Url</h3>
        <v-row no-gutters>
          <v-col cols="12">
            <v-layout column>
              <v-select
                :items="pageUrls"
                v-model="pageDefaultUrl"
                class="max-w-select-lang mb-2"
                hide-details="auto"
                single-line
                outlined
                dense
              ></v-select>
              <template
                v-for="(translatedValue, translatedValueIndex) in page.Title"
              >
                <template v-if="pageDefaultUrl == translatedValueIndex">
                  <v-text-field
                    :key="translatedValueIndex"
                    v-model="page.Url[translatedValueIndex]"
                    class="textInputWidth"
                    hide-details="auto"
                    single-line
                    outlined
                    dense
                  >
                  </v-text-field>
                </template>
              </template>
            </v-layout>
          </v-col>
        </v-row>

        <h3 class="mb-3 mt-3">{{ $t("Unique name") }}</h3>
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="page.UniqueName"
              :placeholder="$t('Unique name')"
              class="textInputWidth"
              hide-details="auto"
              single-line
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>

        <h3 class="mb-3 mt-3">{{ $t("Synchronization URLs") }}</h3>
        <v-row no-gutters>
          <v-col class="px-0" cols="12">
            <v-text-field
              v-model="page.SynchronizationPutUrl"
              placeholder="Put URL"
              class="textInputWidth"
              hide-details="auto"
              single-line
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" class="my-2" v-if="page.RepeatingContent">
            <v-text-field
              v-model="page.SynchronizationBulkPutUrl"
              placeholder="Bulk put URL"
              hide-details="auto"
              class="textInputWidth"
              single-line
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" v-if="page.RepeatingContent">
            <v-text-field
              v-model="page.SynchronizationDeleteUrl"
              placeholder="Delete URL"
              hide-details="auto"
              class="textInputWidth"
              single-line
              outlined
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: [Array, Object],
    },
    pageDefaultUrlProps: {},
    pageUrlsProps: {},
  },
  data() {
    return {
      pageUrls: this.pageUrlsProps,
      pageDefaultUrl: this.pageDefaultUrlProps,
    };
  },
};
</script>

<style>
</style>